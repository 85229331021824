.members-wrapper {
  height: 100%;
  .mini-heading {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    h2 {
      padding-left: 30px;
      color: #212121;
      font-weight: 900;
      border-left: 3px solid #d90a1b;
      line-height: 1.5;
      margin-bottom: 0;
    }
    .members-actions {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      max-width: 500px;
    }
  }
  .members-container {
    width: 100%;
    // padding: 10px 40px 20px 160px;
    // height: 650px;
    // overflow-y: auto;
    // &::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   border-radius: 10px;
    //   background-color: #f5f5f5;
    // }
    // &::-webkit-scrollbar {
    //   width: 10px;
    //   border-radius: 10px;
    //   background-color: #f5f5f5;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 10px;
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   background-color: #d62929;
    // }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 414px) {
  .members-wrapper {
    .mini-heading {
      .ant-col:first-child {
        h2 {
          padding-left: 15px;
        }
      }
      .members-actions {
        .ant-input {
          width: 100% !important;
        }
        .ant-select {
          width: 40% !important;
          margin-left: 10px;
        }
      }
    }
    .members-section {
      flex-direction: column;
      .ant-col {
        width: 100%;
      }
      .ant-col-20 {
        max-width: 100%;
      }
      .members-container {
        margin: 0 !important;
        .member-card {
          display: flex;
          justify-content: center;
        }
        .ant-col {
          padding: 0 !important;
          width: fit-content;
          .ant-card {
          }
        }
      }
      .ask-deputy,
      .ant-col-4 {
        width: 100% !important;
        max-width: 100%;
        margin-top: 15px;
      }
    }
  }
}
