.drejtues-ne-vite-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  b {
    width: 70%;
    padding-bottom: 0.35em;
    border-bottom: 3px solid #dc000b;
    font-size: 1.25rem;
    font-weight: 900;
  }
  .drejtues-ne-vite-content {
    width: 70%;
    margin: 30px 0;
    table {
      width: 100%;
      tr {
        width: 100%;
        padding: 16px 10px;
        display: flex;
        align-items: center;

        &:nth-child(odd) {
          background-color: #f9f9f9;
        }
        td {
          &:nth-child(1) {
            padding-right: 40px;
          }
          &:nth-child(2) {
            width: 300px;
          }
          &:nth-child(3) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .drejtues-ne-vite-wrapper {
    .drejtues-ne-vite-content {
      table {
        tr {
          font-size: 0.8rem;
          td:nth-child(1) {
            padding-right: 20px;
          }
          td:nth-child(2) {
            width: 100px;
            padding-right: 10px;
          }
          td:nth-child(3) {
            width: 180px;
          }
        }
      }
    }
    .drejtues-ne-vite-content,
    b {
      width: 88%;
    }
    b {
      line-height: 1.4;
    }
  }
}
