.sherbime-footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  .sherbime-container {
    width: 70%;
    p {
      margin-bottom: 2rem;
    }
  }
}
