.ask-deputy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .ant-form {
    width: 100%;
  }
  .ant-btn-primary {
    background: #dc000b !important;
    border-color: #dc000b !important;
    height: 40px;
    padding: 0 20px;
    &:disabled {
      border: none;
      background-color: #cccccc !important;
      color: #a09e9e !important;
    }
  }
  .ant-input-number {
    width: 100% !important;
  }
}
