.kalendari-puneve-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  strong {
    margin-top: 100px;
    font-size: 1.02rem;
    .ant-picker-range {
      margin-right: 20px !important;
    }
  }
  p {
    margin: 40px 0;
    font-size: 1rem;
  }
  .ant-table-wrapper {
    width: 80%;
    margin-bottom: 100px;
    .ant-pagination {
      display: flex;
      align-items: center;
      .ant-pagination-item {
        border: none;
        font-size: 1.4rem;
        a {
          color: #000 !important;
        }
      }
      .ant-pagination-item-active {
        border: none;
        font-weight: 600;

        font-size: 1.4rem;
        a {
          color: rgba(0, 0, 0, 0.589) !important;
        }
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          width: 45px !important;
          height: 45px !important;
          background-color: #000 !important;
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .kalendari-puneve-wrapper {
    strong {
      display: flex;
      flex-direction: column;
      margin-top: 80px;
      font-size: 1rem;
      .ant-picker-range {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .ant-table-wrapper {
      width: 90%;
      margin-bottom: 80px;
    }
  }
}

@media only screen and (max-width: 414px) {
  .kalendari-puneve-wrapper {
    strong {
      margin-top: 80px;
      font-size: 0.9rem;
      .ant-picker-range {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    p {
      text-align: center;
    }
    .ant-table-wrapper {
      width: 90%;
      margin-bottom: 80px;
    }
  }
}
