.search-view-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
  .ant-input-group-wrapper {
    width: 60% !important;
    .ant-input {
      height: 55px;
      font-size: 1.2rem;
    }
    .ant-input-search-button {
      height: 55px;
      width: 70px;
      font-size: 1.6rem;
      background-color: #dc000b;
      border-color: #dc000b;
    }
  }
  .ant-alert {
    width: 60%;
  }
  .search-items-section {
    width: 60%;
    ol {
      margin: 0;
      padding: 0;
      list-style-position: inside;
      li {
        padding-left: 10px;
        margin: 13px 0;
        width: fit-content;
        &:hover {
          color: #dc000b;
          cursor: pointer;
          transition: 0.2s;
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .search-view-wrapper {
    margin: 50px 0;
    .ant-input-search {
      width: 80% !important;
      .ant-input {
        height: 40px;
        font-size: 1rem;
      }
      .ant-input-search-button {
        height: 40px;
        font-size: 1.2rem;
        width: 50px;
      }
    }
    .search-items-section {
      width: 80%;
      .search-item-dynam-comp {
        margin-top: 40px;
        b {
          padding-left: 15px;
          font-size: 1.1rem;
          min-height: 0;
        }
      }
    }
  }
}
