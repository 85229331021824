.struktura-footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .img-container {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    img {
      width: 80%;
    }
  }
}
