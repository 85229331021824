.sinjalizime-footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  p {
    width: 70%;
    display: flex;
    flex-direction: column;
    h2 {
      font-weight: bolder;
      margin-bottom: 0.3rem;
    }
    a {
      color: #000;
      transition: 0s !important;
      &:hover {
        color: #dc000b;
        text-decoration: underline;
      }
    }
  }
}
