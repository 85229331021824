.newsList-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  .card {
    height: 200px;
    height: 200px;
    display: flex;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.35rem;
    .img-container {
      width: 300px;
      height: 100%;
      img {
        // max-width:100%;
        // max-height:100%;
        // object-fit: cover;
        width: 300px;
        height: 100%;
        object-fit: cover;
      }
    }
    .card-info {
      width: 100%;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      p {
        // margin-bottom: 0 !important;
      }
      .news-content {
        margin-top: 10px;
      }
      .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        time {
          font-size: 0.7rem;
          line-height: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .newsList-content {
    .img-container {
      height: 170px !important;
      img {
        width: 200px !important;
        height: 170px !important;
      }
    }
    p {
      font-size: 0.8rem;
      line-height: 1.5;
    }
    .view-act {
      font-size: 0.6rem;
      padding: 0.25rem 0;
    }
    time {
      font-size: 0.7rem !important;
    }
  }
}

@media only screen and (max-width: 650px) {
  .newsList-content {
    .img-container {
      height: 140px !important;
      img {
        width: 180px !important;
        height: 140px !important;
      }
    }
    p {
      font-size: 0.7rem !important;
    }
    .view-act {
      font-size: 0.5rem;
      padding: 0.25rem 0;
    }
    time {
      font-size: 0.65rem !important;
    }
  }
}

@media only screen and (max-width: 414px) {
  .newsList-content {
    .img-container {
      height: 190px !important;
      img {
        width: 150px !important;
        height: 190px !important;
      }
    }
    p {
      font-size: 0.72rem !important;
    }
  }
}
