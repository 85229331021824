.content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  .agendas-wrapper {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 100px 0;
    .agenda-section {
      display: flex;
      flex-direction: column;
      width: 85%;
      margin-right: 30px;
      .agenda-container {
        margin: 50px 0;
        padding: 0 30px;
        border-left: 3px solid #d90a1b;
        &:first-child {
          margin-top: 0;
        }
        h4 {
          font-size: 0.8rem;
          span {
            font-weight: bold;
            padding-left: 5px;
            color: #d90a1b;
          }
        }
        .agenda-title {
          margin-bottom: 7px;
          font-weight: bolder;
          font-size: 1.4rem;
          line-height: 28px;
        }
        .agenda-body-content {
          margin-top: 30px;
          p {
            margin: 0;

            span {
              font-size: 18px !important;
              line-height: 1.2;
            }
          }
        }
      }
    }
    .newsletter-wrapper {
      position: sticky;
      right: 0;
      top: 0;
      width: 300px;
      height: 700px;
      border: 1px solid red;
    }
  }
}

@media only screen and (max-width: 414px) {
  .agendas-wrapper {
    margin: 30px 0 !important;
    flex-direction: column;
    .agenda-section {
      width: 100% !important;
      margin: 0 !important;
      .agenda-container {
        padding: 0 0 0 15px !important;
        margin-bottom: 0 !important;
        &:last-child {
          margin-bottom: 40px !important;
        }
        .agenda-title {
          line-height: 1.2 !important;
          font-size: 1.1rem !important;
        }
        .agenda-body-content {
          margin-top: 10px !important;
          ol {
            padding-left: 15px;
          }
        }
      }
    }
    .newsletter-wrapper {
      position: relative !important;
      width: 100% !important;
      height: 190px !important;
      h4 {
        font-size: 1rem;
        line-height: 1.3;
      }
    }
  }
}
