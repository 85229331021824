.news-filters {
  .fliters {
    display: flex;
    justify-content: space-between;
    .ant-row:first-child {
      width: 270px;
    }
  }
  .ant-btn-primary {
    background-color: #212121 !important;
    border: 1px solid rgba(190, 190, 190, 0.5) !important;
    border-radius: 3px;
    // width: 120px;
    // height: 40px;
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    &:hover {
      color: #fff;
      filter: brightness(1.3);
    }
    &:first-child {
      margin-right: 20px;
    }
  }
  .filter-buttons {
    display: flex;
  }
}
