.akte-ligjore-footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  span {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 7px 0;
    .anticon {
      width: auto;
    }
    a {
      padding-left: 5px;
      color: #000;
      transition: color 0s !important;
      &:hover {
        color: #dc000b;
        text-decoration: underline;
      }
    }
  }
  .ant-collapse {
    width: 70%;
    p {
      display: flex;
      align-items: center;
      span {
        width: auto;
      }
      a {
        padding-left: 5px;
        color: #000;
        transition: 0s !important;
        &:hover {
          color: #dc000b;
          text-decoration: underline;
        }
      }
    }
  }
}
