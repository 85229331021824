.act-card-wrapper {
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  padding: 14px;
  margin-top: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: -1px;
    right: -1px;
    z-index: 10;
    background-image: url(../../../assets/images/top-border-detail.png);
    background-color: red;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px 10px;
  }
  .act-top-details {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h5 {
      font-size: 1rem;
      font-weight: 600;
    }
    h6 {
      font-size: 0.75rem;
    }
  }
  .act-bottom-details {
    height: 50%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 0.69rem;
    }
  }
}
