.dok-comp {
  width: 31%;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  border: 1px solid #d8d8d8;
  margin-bottom: 30px;
  padding: 10px 15px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: #f5f3f4;
    transition: 0.1s;
  }
  .ant-col {
    &:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      // background-color: rgba(209, 0, 0, 0.8);
      height: 70px;
      width: 70px;
      &:hover {
        // background-color: rgb(150, 4, 11);
        transition: 0.3s;
        cursor: pointer;
      }
    }
  }
  .doc-details {
    padding-left: 15px;
    span {
      display: flex;
      font-size: 12px;
      font-style: italic;
      margin-top: 5px;
      p {
        margin: 0;
        font-size: 12px;
      }
    }
    p {
      color: #212121;
      font-size: 14px;
      font-weight: bolder;
      margin-bottom: 0;
    }
  }
}
