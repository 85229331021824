.outer-circle-2 {
  border-radius: 50%;
  width: 55px;
  aspect-ratio: 1;
  background-color: rgba(255, 0, 0, 0.315);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  animation: pulse-red 2s infinite;

  .outer-circle-1 {
    border-radius: 50%;
    width: 45px;
    aspect-ratio: 1;
    background-color: rgba(255, 0, 0, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    animation: pulse-red 2s infinite;
    .inner-circle {
      border-radius: 50%;
      width: 38px;
      aspect-ratio: 1;
      background-color: rgb(255, 0, 0);
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      .live-text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border: 1px solid white;
        font-size: 10px;
        line-height: 11px;
        border-radius: 2px;
        padding-left: 1.5px;
        padding-right: 1.5px;
      }
    }
    .no-live {
      transform: inherit;
      animation: inherit;
      background-color: rgba(15, 0, 0, 0.096);
    }
  }
  .no-live {
    transform: inherit;
    animation: inherit;
    background-color: rgba(15, 0, 0, 0.315);
  }
}

.no-live {
  transform: inherit;
  animation: inherit;
  background-color: rgba(15, 0, 0, 0.315);
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
