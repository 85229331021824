.contacts-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
  b {
    border-left: 3px solid #dc000b;
    font-size: 1.25rem;
    padding-left: 20px;
    font-weight: 900;
    min-height: 45px;
    height: auto;
    display: flex;
    align-items: center;
  }
  .contacts-content {
    width: 79%;
    margin: 25px 0;
    display: flex;
    justify-content: space-between;
    .contacts-details {
      width: 45%;
      max-height: 500px;
      overflow: auto;
      p {
        margin: 50px 0;
        font-size: 1.1rem;
        opacity: 0.65;
      }
    }
    .contact-card-wrapper {
      display: flex;
      .ant-card {
        max-width: 300px;
        margin: 0 20px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        .ant-card-meta-title {
          display: flex !important;
          justify-content: center !important;
        }
        .ant-card-meta-description {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .contacts-wrapper {
    .contacts-content {
      display: flex;
      flex-direction: column;
      .contacts-details {
        width: 100%;
        p {
          margin: 30px 0;
        }
      }
      .contact-card-wrapper {
        margin-top: 30px !important;
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .contacts-wrapper {
    .contacts-content {
      width: 88%;
    }
  }
}
