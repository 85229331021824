.kryetari-details-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .ant-avatar-circle {
    opacity: 1;
    cursor: pointer;
    border: 2px solid red;
    border-radius: 50%;
    &:hover {
      box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.75);
      transition: 0.3s;
    }
  }
  .dots {
    width: 100px;
    height: auto;
    opacity: 0.4;
    cursor: pointer;
  }
  strong {
    font-size: 1.2rem;
    margin: 5px 0;
    opacity: 0.8;
  }
  .go-to-deeputet:hover {
    text-decoration: underline;
    color: #dc000b;
    cursor: pointer;
  }
  p {
    margin: 5px 0 10px;
    line-height: 1.4;
    opacity: 0.75;
  }
  span {
    display: flex;
    align-items: center;
    opacity: 0.75;
    &:hover {
      cursor: pointer;
      color: #dc000b;
    }
    h6 {
      font-size: 0.8rem;
      margin-bottom: 0;
      &:hover {
        color: #dc000b;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .kryetari-details-wrapper {
    p {
      display: none !important;
    }
    strong {
      font-size: 1rem;
    }
  }
}
