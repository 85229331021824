.newsList-wrapper {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: space-around;
  .newList-content-wrapper {
    b {
      border-left: 3px solid #dc000b;
      font-size: 1.6rem;
      padding-left: 40px;
      font-weight: 900;
      min-height: 45px;
      height: auto;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      margin-top: 40px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .newList-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      .ant-pagination {
        margin-top: 30px;
      }
    }
  }
  .newsletter-wrapper {
    position: sticky;
    right: 0;
    top: 0;
    width: 300px;
    height: 700px;
    border: 1px solid red;
  }
}

@media only screen and (max-width: 1250px) {
  .newsList-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    .newList-content-wrapper {
      width: 80% !important;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .newsList-wrapper {
    .newList-content-wrapper {
      width: 80% !important;
    }
  }
}

@media only screen and (max-width: 414px) {
  .newsList-wrapper {
    margin: 50px 0;
    .newList-content-wrapper {
      width: 80% !important;
      b {
        padding-left: 15px;
        font-size: 1.1rem;
        min-height: 0;
        margin-bottom: 20px;
      }
      .news-filters {
        .fliters {
          flex-direction: column;
          .ant-form-item {
            width: 90%;
          }
        }
        .filter-buttons {
          .ant-btn {
            font-size: 0.8rem;
            padding: 12px 10px;
          }
        }
      }
      .newList-content {
        .newsList-content {
          margin-bottom: 25px;
          .card {
            height: 130px;
            .img-container {
              height: 130px !important;
            }
            .card-info {
              padding: 0 0.7rem;
              p {
                line-height: 1.2;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 150px;
              }
              .view-act {
                width: 70px !important;
                font-size: 0.48rem;
              }
            }
          }
        }
        .ant-pagination {
          margin-top: 0;
        }
      }
    }
    .newsletter-wrapper {
      width: 80% !important;
      height: 190px !important;
      margin-top: 50px;
      h4 {
        font-size: 1rem;
        line-height: 1.3;
      }
    }
  }
}
