.rendi-dites-comp {
  width: 31%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  margin-bottom: 30px;
  // padding-left: 20px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: #f5f3f4;
    transition: 0.1s;
  }
  .ant-col {
    &:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      height: 70px;
      width: 70px;
      img {
        filter: brightness(2);
      }
    }
    p {
      // padding-left: 10px;
      color: #212121;
      font-size: 14px;
      font-weight: bolder;
      padding-top: 15px;
    }
  }
}
