$main-color: #dc000b;

@mixin tabStyle {
  .non-active-tab {
    padding: 16px 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.144);
    font-size: 1rem;
    line-height: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:hover {
      border: 1px solid $main-color;
      background-color: $main-color;
      transition: 0.2s;
      color: #fff;
    }
  }
  .active-tab {
    padding: 16px 0;
    width: 100%;
    border: 1px solid $main-color;
    font-size: 1rem;
    background-color: $main-color;
    color: #fff;
    line-height: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-tabs-comp-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0;
  b {
    width: 80%;
    border-left: 3px solid $main-color;
    font-size: 1.25rem;
    padding-left: 20px;
    font-weight: 900;
    min-height: 45px;
    height: auto;
    display: flex;
    align-items: center;
  }
  .custom-tabs-vertical-type {
    width: 80%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .vertical-custom-tabs {
      min-width: 250px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      @include tabStyle;
      span {
        text-align: center;
      }
    }
    .tab-content {
      width: 100%;
      margin-left: 35px;
      height: auto;
    }
  }
  .custom-tabs-horizontal-type {
    width: 80%;
    // margin-top: 40px;
    display: flex;
    flex-direction: column;
    .horizontal-custom-tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 55px;
      @include tabStyle;
    }
    .tab-content {
      width: 100%;
      height: auto;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .custom-tabs-comp-wrapper {
    .custom-tabs-horizontal-type {
      flex-direction: row;
      .horizontal-custom-tabs,
      .custom-tabs-vertical-type {
        min-width: 250px;
        width: auto;
        flex-direction: column;
        height: 55px;
      }
      .tab-content {
        word-wrap: break-word;
        max-height: 300px;
        overflow: auto;
        margin-left: 35px;
        margin-top: 0;
      }
    }
  }
}

// @media only screen and (max-width: 414px) {
//   .custom-tabs-comp-wrapper {
//     b {
//       width: 88%;
//     }
//     b {
//       line-height: 1.4;
//     }
//     .custom-tabs-horizontal-type,
//     .custom-tabs-vertical-type {
//       height: auto;
//       width: 88%;
//       margin-top: 30px;
//       .horizontal-custom-tabs,
//       .vertical-custom-tabs {
//         min-width: 130px;
//         width: 50px;
//         flex-direction: row;
//         flex-wrap: wrap;
//         height: 55px;
//         span {
//           font-size: 0.8em !important;
//         }
//       }
//       .tab-content {
//         overflow: auto;
//         max-width: 220px;
//         word-wrap: break-word;
//         max-height: 270px;
//         padding-left: 15px;
//         margin-left: 0;
//         justify-content: flex-start;
//       }
//     }
//   }
// }
