.newsList-wrapper {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: space-around;
  .newList-content-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    .ant-pagination {
      margin-top: 30px;
    }
  }
  .newsletter-wrapper {
    position: sticky;
    right: 0;
    top: 0;
    width: 300px;
    height: 700px;
    border: 1px solid red;
  }
}

@media only screen and (max-width: 1250px) {
  .newsList-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    .newList-content-wrapper {
      width: 80% !important;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .newsList-wrapper {
    .newList-content-wrapper {
      width: 80% !important;
    }
  }
}

@media only screen and (max-width: 414px) {
  .newsList-wrapper {
    margin: 50px 0;
    .newList-content-wrapper {
      width: 80% !important;
    }
  }
}
