@mixin radiusStyle {
  position: absolute;
  width: 25%;
  border-top: 0.1rem dotted rgba(0, 0, 0, 0.329);
}

.kryetari-graph-wrapper {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .kryetari-graph-content {
    width: 1150px;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top-section,
    .bottom-section {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .middle-circle-section {
      width: 100%;
      height: 450px;
      position: relative;
      display: flex;
      .side-details {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .circle {
        width: 230px;
        height: 230px;
        border-radius: 50%;
        border: 1px dotted rgba(0, 0, 0, 0.329);
        align-self: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .circle-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          h4:hover {
            text-decoration: underline;
            color: #dc000b;
          }
          img {
            width: auto;
            height: 150px;
            background: transparent;
            cursor: pointer;
            &:hover {
              transform: scale(1.03);
              transition: 0.4s;
            }
          }
        }

        .rad-1 {
          @include radiusStyle;
          left: 100%;
          top: 30%;
          transform: skewY(-20deg);
        }
        .rad-2 {
          @include radiusStyle;
          right: 100%;
          top: 30%;
          transform: skewY(20deg);
        }
        .rad-3 {
          @include radiusStyle;
          left: 100%;
          top: 70%;
          transform: skewY(20deg);
        }
        .rad-4 {
          @include radiusStyle;
          right: 100%;
          top: 70%;
          transform: skewY(-20deg);
        }
        .rad-5 {
          @include radiusStyle;
          top: 115%;
          transform: rotate(90deg);
        }
        .rad-6 {
          @include radiusStyle;
          bottom: 115%;
          transform: rotate(90deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 1170px) {
  .kryetari-graph-wrapper {
    .kryetari-graph-content {
      width: 970px;
      .circle {
        width: 200px !important;
        height: 200px !important;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .kryetari-graph-wrapper {
    .kryetari-graph-content {
      width: 950px;
      margin: 0 20px;
      .circle {
        width: 180px !important;
        height: 180px !important;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .kryetari-graph-wrapper {
    .kryetari-graph-content {
      flex-direction: row !important;
      flex-wrap: wrap !important;
      margin: 0 50px !important;
      .circle {
        display: none !important;
      }
      .top-section,
      .bottom-section {
        width: 50%;
      }
      .middle-circle-section {
        height: 380px !important;
        justify-content: space-around !important;
        .side-details {
          width: 240px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .kryetari-graph-wrapper {
    .kryetari-graph-content {
      .top-section {
        margin-bottom: 25px;
      }
      .bottom-section {
        margin-top: 25px;
      }
      .middle-circle-section {
        width: 440px;
        height: 550px !important;
        flex-direction: column !important;
        align-items: center !important;
        margin: 100px 0 !important;
        .side-details {
          width: 100% !important;
          align-items: center !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .kryetari-graph-wrapper {
    margin: 30px 0 !important;
    .kryetari-graph-content {
      .top-section {
        margin-bottom: 0px !important;
      }
      .bottom-section {
        margin-top: 0px;
      }
      .middle-circle-section {
        width: 340px;
        flex-direction: column !important;
        align-items: center !important;
        margin: 25px 0 !important;
        .side-details {
          width: 100% !important;
          align-items: center !important;
        }
      }
    }
  }
}
