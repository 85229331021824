.newsletter-wrapper {
  background: linear-gradient(
      150deg,
      rgba(12, 12, 12, 0.8) 0%,
      rgba(5, 3, 3, 0.8) 30%,
      rgba(204, 0, 0, 0.8) 75%,
      rgba(209, 0, 0, 0.8) 100%
    ),
    url("../../../assets/images/header-backround.jpg"),
    -100% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  h4 {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .ant-btn-primary {
    background: #dc000b !important;
    border-color: #dc000b !important;
  }
  .ant-input {
    width: 100%;
  }
  .ant-form-item:nth-child(2) {
    margin-bottom: 0;
  }
  .ant-form-item-explain-error {
    color: white !important;
  }
}
