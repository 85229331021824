.zgjedhjet-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  b {
    width: 70%;
    padding-bottom: 0.35em;
    border-bottom: 3px solid #dc000b;
    font-size: 1.25rem;
    font-weight: 900;
  }
  .zgjedhjet-content {
    width: 70%;
    margin: 30px 0;
    .top_photos {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin: 20px 0 50px;
      img {
        max-width: 50%;
        height: 400px;
      }
    }
    .subjektet_photo {
      max-width: 100%;
      height: auto;
    }
  }
}

@media only screen and (max-width: 950px) {
  .zgjedhjet-wrapper {
    b {
      width: 80%;
    }
    .zgjedhjet-content {
      .top_photos {
        display: flex;
        img {
          max-width: 90%;
          height: 280px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .zgjedhjet-wrapper {
    b {
      width: 80%;
    }
    .zgjedhjet-content {
      .top_photos {
        display: flex;
        flex-direction: column;
        margin: 0 0 10px;
        img:last-child {
          margin-top: 15px;
        }
        img {
          max-width: 90%;
          height: 280px;
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .zgjedhjet-wrapper {
    b {
      width: 75;
    }
    .zgjedhjet-content {
      .top_photos {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          max-width: 100%;
          height: 280px;
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .zgjedhjet-wrapper {
    .zgjedhjet-content,
    b {
      width: 80%;
    }
    b {
      line-height: 1.4;
    }
  }
}
