.social-links-wrapper {
  display: flex;
  justify-content: space-between;
  width: 180px;
  div {
    padding: 2px 6px;
    &:hover {
      filter: brightness(1.4);
      cursor: pointer;
      transition: 0.2s;
    }
  }
  img {
    width: 18px;
    height: 13px;
  }
}
