.ant-card {
  max-width: 300px;
  margin: 0 20px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  .ant-card-meta-title {
    display: flex !important;
    justify-content: center !important;
  }
  .ant-card-meta-description {
    display: flex;
    justify-content: center;
  }
}
