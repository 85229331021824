.komisioni-dokumentat-wrapper {
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
  h2 {
    padding-left: 30px;
    color: #212121;
    font-weight: 900;
    border-left: 3px solid #d90a1b;
    line-height: 1.5;
    margin-bottom: 0;
  }
  .rendi-list {
    margin: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    .dok-comp {
      margin-right: 20px;
    }
  }
  .secondary {
    align-self: center;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .komisioni-dokumentat-wrapper {
    .rendi-list {
      .dok-comp {
        margin-right: 0px;
        width: 100%;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .komisioni-rendi-dites-wrapper {
    .rendi-list {
      .rendi-dites-comp {
        margin-right: 10px;
        width: 45%;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .komisioni-rendi-dites-wrapper {
    .rendi-list {
      .rendi-dites-comp {
        margin-right: 10px;
        width: 45%;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .komisioni-rendi-dites-wrapper {
    .rendi-list {
      .rendi-dites-comp {
        margin-right: 10px;
        width: 45%;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .komisioni-rendi-dites-wrapper {
    .rendi-list {
      .rendi-dites-comp {
        margin-right: 20px;
        width: 31%;
      }
    }
  }
}
