.search-item-dynam-comp {
  margin-top: 80px;
  b {
    border-left: 3px solid #dc000b;
    font-size: 1.6rem;
    padding-left: 40px;
    font-weight: 900;
    min-height: 45px;
    display: flex;
    align-items: center;
  }
  .search-item-content {
    max-height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
  }
}
