.news-wrapper {
  .news-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .news-content {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .posted-date {
        width: 80%;
        margin-top: 50px;
        font-weight: lighter;
        margin-bottom: 0px;
      }
      .news-carousel-container {
        width: 80%;
        //height: 600px;
        overflow: hidden;
        .news-featured-img {
          width: 100%;
          //height: 600px;
          object-fit: cover;
        }
        .slick-dots-bottom {
          // margin: 0;
          // width: 100%;
          // height: 100%;
          // bottom: 0;
          // justify-content: space-between;
          // align-items: center;
          li {
            width: 30px;
            button {
              height: 7px;
            }
          }
          // li {
          //   height: 100%;
          //   margin: 0;
          //   button {
          //     height: 100%;
          //     background-color: red;
          //   }
          // }
          // .slick-active {
          //   transition: none !important;
          // }
        }
      }
      .main-photo {
        width: 80%;
        //height: 600px;
        overflow: hidden;
        img {
          width: 100%;
          //height: 600px;
          object-fit: cover;
        }
      }
      .news-title {
        margin: 30px 0;
        width: 80%;
        font-weight: bolder;
        font-size: 1.4rem;
        border-left: 3px solid #d90a1b;
        padding-left: 30px;
        line-height: 28px;
      }
      .news-body-content {
        font-size: 18px;
        width: 80%;
        margin-top: 20px;
        margin-bottom: 30px;
        p {
          line-height: 1.2;
        }
        &::first-letter {
          font-size: 2.5rem;
          font-weight: 900;
          line-height: 0.8;
        }
      }
      .news-documents {
        width: 80%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .news-doc {
          color: #104392;
          cursor: pointer;
        }
        .komisioni-dokumentat-wrapper {
          margin: 0;
        }
      }
      .suggested-news-wrapper {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .news-wrapper {
    .posted-date {
      width: 80% !important;
      margin-top: 20px !important;
    }
    .news-title {
      width: 80% !important;
      padding-left: 15px !important;
      font-size: 1.1rem !important;
      line-height: 1.2 !important;
      margin-top: 15px !important;
    }
    .news-carousel-container {
      width: 80% !important;
      height: 200px !important;
    }
    .news-body-content {
      width: 80% !important;
      font-size: 0.9rem !important;
      letter-spacing: 1px !important;
      margin-bottom: 0 !important;
    }
  }
}
