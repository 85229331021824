.libraria-akteve-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .libraria-akteve-content {
    width: 60%;
    margin: 80px 0;
    .akte-filters-section {
      b {
        border-left: 3px solid #dc000b;
        font-size: 1.6rem;
        padding-left: 40px;
        font-weight: 900;
        min-height: 45px;
        height: auto;
        display: flex;
        align-items: center;
        &:last-child {
          margin-top: 120px !important;
        }
      }
      .first-filters {
        display: flex;
      }
    }
    .ant-pagination {
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 414px) {
  .libraria-akteve-wrapper {
    .libraria-akteve-content {
      width: 80%;
      margin: 50px 0;
      .akte-filters-section {
        b {
          padding-left: 15px;
          min-height: 0;
          font-size: 1.1rem;
        }
        .acts-filters {
          margin: 20px 0;
          .ant-form {
            .ant-row:first-child {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
          }
          .first-filters {
            display: flex;
          }
          .ant-btn {
            font-size: 0.8rem;
            padding: 12px 10px;
          }
        }
        .akte-wrapper {
          margin-top: 20px;
          .act-card-wrapper {
            .act-top-details {
              h5 {
                font-size: 0.8rem;
              }
            }
            .act-bottom-details {
              .view-act {
                width: 80px !important;
                font-size: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
