.komision-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .komision-content-wrapper {
    width: 80%;
    margin: 50px 0;
  }
}

@media only screen and (max-width: 414px) {
  .komision-content-wrapper {
    margin: 50px 0 !important;
    strong {
      h2 {
        padding-left: 15px;
        font-size: 1.1rem;
      }
    }
    .intro-wrapper {
      .ant-col:first-child {
        h2 {
          padding-left: 15px;
          font-size: 1.1rem;
        }
        p {
          margin-top: 20px;
          line-height: 1.5;
        }
      }
    }
    .news-related-cards {
      width: 100% !important;
    }
  }
}
