.procesverbale-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .procesverbale-content {
    width: 80%;
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    .procesverbale-fitlers {
      b {
        border-left: 3px solid #dc000b;
        font-size: 1.6rem;
        padding-left: 40px;
        font-weight: 900;
        min-height: 45px;
        height: auto;
        display: flex;
        align-items: center;
        &:last-child {
          margin: 50px 0 30px;
        }
      }
      .strukture-filter {
        max-width: 400px;
        .ant-form-item {
          margin: 30px 0 20px;
        }
      }
      .ant-btn-primary {
        background-color: #212121 !important;
        border: 1px solid rgba(190, 190, 190, 0.5) !important;
        border-radius: 3px;
        padding: 20px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        margin-top: 10px;
        &:hover {
          color: #fff;
          filter: brightness(1.3);
        }
        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .procesverbale-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 15px;
      .dok-comp {
        flex-basis: 32%;
        height: auto;
        padding: 10px 20px;
        margin-bottom: 0;
        .doc-details {
          p {
            line-height: 1.5;
          }
        }
      }
    }
  }
  .ant-pagination {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 414px) {
  .procesverbale-wrapper {
    .procesverbale-content {
      margin: 50px 0;
      .procesverbale-fitlers {
        b {
          padding-left: 15px;
          min-height: 0;
          font-size: 1.1rem;
          &:last-child {
            margin: 30px 0 20px !important;
          }
        }
        .strukture-filter {
          min-width: 100%;
          .ant-form-item {
            margin: 20px 0 10px !important;
          }
        }
        .ant-btn-primary {
          padding: 15px 10px;
          font-size: 1rem;
        }
      }
      .procesverbale-cards {
        display: flex;
        flex-direction: column;
        .dok-comp {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
}
