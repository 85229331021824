$seconday-color-btn: #212121;
$clear-filter-color-btn: rgba(190, 190, 190, 0.5);

.dynamic-button {
  cursor: pointer;
  color: white;
  border: 1px solid white;
  border-radius: 3px;
  width: fit-content;
  padding: 1.5% 3.5%;
  transition: all 150ms ease-in;
  &:hover {
    background-color: white;
    color: black;
  }
}

.secondary {
  background-color: $seconday-color-btn !important;
  border: 1px solid $seconday-color-btn !important;
  border-radius: 3px;
  padding: 0.8% 2%;
  &:hover {
    color: #fff;
    filter: brightness(1.3);
  }
}

.clear-filters {
  background-color: $clear-filter-color-btn !important;
  border: none !important;
  border-radius: 3px;
  padding: 1% 3%;
  color: #000;
  margin-left: 20px;
  &:hover {
    filter: brightness(1.1);
  }
}

.view-act {
  text-transform: uppercase;
  border: 1px solid #dc000b;
  padding: 5px 10px;
  border-radius: 30px;
  color: #000;
  font-size: 0.65rem;
  letter-spacing: 0.06em;
  width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff;
    background-color: #dc000b;
  }
}
