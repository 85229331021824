$bckg-color: #f8f8f8;

.latest-acts-wrapper {
  max-width: 100%;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bckg-color;
  h3 {
    font-size: 1.5rem;
    font-weight: 900;
  }
  .act-content {
    width: 80%;
    margin-bottom: 30px;
    padding: 25px;
    display: flex;
    align-items: center;
    height: auto;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    cursor: pointer;
    h4 {
      width: 65%;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35%;
      h5 {
        margin-left: 10px;
      }
    }
    &:hover {
      background-color: #f5f3f4;
      transition: 0.1s;
    }
  }
}

@media (max-width: 414px) {
  .latest-acts-wrapper {
    .act-content {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 15px !important;
      h4 {
        width: 100%;
        margin-bottom: 10px;
      }
      img {
        width: 45px;
      }
      span {
        width: 100%;
        justify-content: flex-start;
        border-top: 1px solid #d8d8d833;
        padding-top: 10px;
      }
    }
  }
}
