.legjislaturat-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  b {
    width: 70%;
    padding-bottom: 0.35em;
    border-bottom: 3px solid #dc000b;
    font-size: 1.25rem;
    font-weight: 900;
  }
  .legjislaturat-content {
    width: 70%;
    margin: 30px 0;
    .ant-tabs-tab {
      padding-left: 0 !important;
      &:hover {
        color: rgba(220, 0, 11, 1);
      }
    }
    .ant-tabs-ink-bar {
      border-bottom: 38px solid rgba(220, 0, 11, 1) !important;
    }
    .ant-tabs-tab-active {
      background: rgba(220, 0, 11, 0.3);
      padding-left: 0.8rem !important;
      .ant-tabs-tab-btn {
        color: rgba(220, 0, 11, 1);
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .legjislaturat-wrapper {
    .legjislaturat-content,
    b {
      width: 88%;
    }
    b {
      line-height: 1.4;
    }
  }
}
