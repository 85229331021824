.loading-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  // padding: 150px 0;
  img {
    width: auto;
    height: 100px;
    animation: flip-with-rotate 2.5s infinite linear;
  }
}

.long-data-loading {
  min-height: 350px;
}

@keyframes flip-with-rotate {
  0% {
    transform: perspective(400px) rotateY(0);
  }

  100% {
    transform: perspective(400px) rotateY(360deg);
  }
}
