.ndertesa-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  b {
    width: 70%;
    padding-bottom: 0.35em;
    border-bottom: 3px solid #dc000b;
    font-size: 1.25rem;
    font-weight: 900;
  }
  .ndertesa-content {
    width: 70%;
    margin: 30px 0;
    h3 {
      font-size: 1rem;
      font-weight: bold;
      margin-top: 15px;
      &:first-child {
        margin: 0;
      }
    }
    p {
      font-size: 0.875rem;
      letter-spacing: 0.05rem;
      line-height: 1.6;
      color: #333;
    }
  }
}

@media only screen and (max-width: 414px) {
  .ndertesa-wrapper {
    .ndertesa-content,
    b {
      width: 88%;
    }
    b {
      line-height: 1.4;
    }
  }
}
