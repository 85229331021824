.live-vod-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .live-vod-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .loading-space {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
    }
    .live-stream-comp {
      width: 45%;
      aspect-ratio: 16/9;
      border-radius: 20px;
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 30px;
      div {
        width: 100%;
        height: 100%;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
