.agenda {
  display: flex;
  flex-direction: column;
  height: 80%;
  padding-left: 10%;
  .loading-logo {
    min-height: 230px;
    img {
      height: 70px;
    }
  }
  h1 {
    color: white;
    font-size: 20px;
  }
  .day-picker {
    display: flex;
    width: fit-content;
    border-radius: 9px;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.5);
    .day-pick {
      color: white;
      padding: 5px 10px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      cursor: pointer;
      transition: all 150ms ease-in;
    }
    .day-pick:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
    span {
      display: flex;
    }
    .selected {
      background-color: white;
      border: none;
      color: black;
    }
    .selected:hover {
      background-color: white;
    }
  }
  .agenda-list {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
    overflow-y: auto;
    // height: 30vh;
    // width: 68%;
    margin-top: 2%;
    .agenda-item {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.25);
      margin-bottom: 3%;
      margin-top: 3%;
      border-radius: 3px;
      padding: 3%;
      &:hover {
        filter: brightness(0.8);
        cursor: pointer;
        transition: 0.3s;
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .agenda-time {
        display: flex;
        align-items: center;
        color: #ff7e85;
        font-weight: bolder;
        font-size: 20px;
      }
      .agenda-description {
        width: 100%;
        color: white;
        font-size: 16px;
        p {
          margin-bottom: 0.6rem;
          line-height: 1.3;
          span {
            background: transparent !important;
            color: #fff !important;
            font-size: 1.05rem !important;
          }
        }
      }
    }
    .mbledhje-warning {
      width: 100%;
      min-height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 414px) {
  .mbledhje-warning {
    min-height: 60px !important;
  }
}
