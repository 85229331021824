.instituti-parl-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  a {
    width: 70%;
    margin-bottom: 15px;
    color: #000;
    transition: 0s !important;
    &:hover {
      color: #dc000b;
      text-decoration: underline;
    }
  }
}
