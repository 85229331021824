.deputet-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  .deputet-loading {
    margin: 100px 0;
  }
  .content-wrapper {
    padding: 50px;
    width: 85%;
    position: relative;
    .sticky-pos-content {
      position: sticky;
      right: 0;
      top: 0;
      max-height: 700px;
      .profili-social-links {
        margin-top: 20px;
      }
      h2 {
        margin-top: 20px;
      }
      b {
        font-size: 1rem;
        margin-right: 3px;
      }
      h5 {
        font-size: 0.8rem;
      }
    }
    .profile-pic {
      width: 300px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 360px;
    }
    h2 {
      font-weight: bolder;
      font-size: 26px;
    }
    .deputet-details {
      display: flex;
      flex-direction: column;
      h5 {
        display: flex;
        align-items: center;
        font-size: 0.95rem;
        &:nth-child(2) {
          margin-bottom: 0;
        }
        a {
          text-decoration: underline;
          color: #dc000b;
        }
      }
      .jeteshkrimi-link {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.85);
        &:hover {
          color: #dc000b;
        }
      }
    }
    .third-col {
      width: 100%;
      height: 400px;
      background-color: red;
    }
  }
}

@media only screen and (max-width: 414px) {
  .deputet-wrapper {
    .content-wrapper {
      padding: 0;
      align-items: center;
      margin: 50px 0;
      .sticky-pos-content {
        position: relative;
        display: flex;
        .profile-pic {
          width: 115px;
          height: 115px;
        }
        .deputet-content {
          padding-left: 10px;
          h2 {
            margin: 0 0 10px 0;
            font-size: 1rem;
            font-weight: bolder;
          }
          .deputet-details {
            h5 {
              display: flex;
              align-items: flex-start;
              font-size: 0.8rem;
              margin-bottom: 0.2em;
              b {
                font-size: 0.8rem;
              }
            }
          }
          .social-links-wrapper {
            width: 150px;
            margin-top: 10px;
          }
        }
        .ask-deputy {
          width: 100%;
          padding-top: 50px;
          #nest-messages_Pyetje {
            height: 100px;
          }
          .ant-form-horizontal {
            .ant-row:last-child {
              margin: 0;
            }
          }
        }
      }
      .custom-tabs-comp-wrapper {
        margin-top: 50px;
        align-items: flex-start;
        .custom-tabs-horizontal-type {
          width: 100%;
          flex-direction: column;
          .horizontal-custom-tabs {
            display: flex;
            flex-direction: row;
            width: 100%;
            flex-wrap: nowrap;
            height: auto;
            span {
              padding: 12px 0;
              font-size: 14px;
            }
          }
          .tab-content {
            margin-left: 0;
            margin-top: 15px;
            max-height: 500px;
            height: auto;
            .struktura-list-wrapper {
              height: auto;
            }
            .ant-alert {
              margin-bottom: 10px;
              width: 100%;
              border: none;
              text-align: center;
              font-size: 0.79rem;
            }
          }
        }
      }
    }
  }
}
