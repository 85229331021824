.kushtetuta-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  b {
    width: 70%;
    padding-bottom: 0.35em;
    border-bottom: 3px solid #dc000b;
    font-size: 1.25rem;
    font-weight: 900;
  }
  .kushtetuta-img-wrapper {
    align-self: flex-start;
    margin-left: calc(100% - 85%);
    margin-top: 20px;
    width: 800px;
    max-height: auto;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  .kushtetuta-content {
    width: 70%;
    margin: 30px 0;
    a {
      margin-left: 7px;
    }
  }
}

@media only screen and (max-width: 414px) {
  .kushtetuta-wrapper {
    .kushtetuta-content,
    b {
      width: 88%;
    }
    b {
      line-height: 1.4;
    }
    .kushtetuta-img-wrapper {
      margin-top: 25px;
      margin-left: calc(100% - 94%);
      height: auto;
      width: 300px;
    }
  }
}
