.kontakte-footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  p,
  b {
    width: 70%;
  }
  b:first-child {
    margin-bottom: 20px;
  }
}
