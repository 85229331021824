$link-color: #fff;

.footer-wrapper {
  width: 100%;
  flex-direction: column;
  align-items: center;
  .footer-links {
    transition: all 350ms linear;
    background: linear-gradient(
        150deg,
        rgba(12, 12, 12, 0.8) 0%,
        rgba(5, 3, 3, 0.8) 30%,
        rgba(204, 0, 0, 0.8) 75%,
        rgba(209, 0, 0, 0.8) 100%
      ),
      url("../../../assets/images/header-backround.jpg"),
      -100% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0;
    width: 100%;
    height: auto;
    .logo-part {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 110px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
          transition: 0.4s;
        }
      }
      .logo-name {
        font-size: 16px;
        font-family: "Times New Roman", Times, serif;
        color: #fff;
        b {
          font-weight: 100;
          font-size: 22px;
        }
      }
    }
    .links {
      width: 90%;
      min-height: 160px;
      height: 100%;
      color: #fff;
      position: relative;
      margin-top: 25px;
      .ant-col {
        padding-left: 14px !important;
        .list-header {
          color: $link-color;
          font-weight: bold;
          font-size: 16px;
          list-style-image: url(../../../assets/images/listIcon.png);
          margin-top: 50px;
          &:hover {
            cursor: pointer;
            color: $link-color;
            text-decoration: none;
          }
        }
        .collapsed {
          max-height: 0;
          overflow: hidden;
          transition: all 0.5s cubic-bezier(0, 1, 0, 1);
        }

        .collapsed.show {
          height: auto;
          max-height: 9999px;
          transition: all 0.5s cubic-bezier(1, 0, 1, 0);
        }
      }
      ul {
        padding-left: 0;
        li {
          margin: 7px 0;
          font-size: 0.72rem;
          letter-spacing: 0;
          width: 100%;
          &:hover {
            cursor: pointer;
            color: $link-color;
            text-decoration: underline;
          }
        }
      }
      .footer-social-links {
        // position: absolute;
        // bottom: 0;
        height: 100%;
        div {
          padding: 3px 7px;
        }
        img {
          width: 18px;
          height: 13px;
        }
      }
    }
  }
  img {
    width: 130px;
  }
  .copyright {
    width: 100%;
    height: auto;
    background-color: #212121;
    display: flex;
    justify-content: space-around;
    align-items: center;
    p {
      margin-bottom: 0;
      color: #fff;
      font-size: 12px;
      text-align: center;
      padding: 15px 0;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .copyright {
    flex-direction: column;
    p:last-child {
      padding-top: 0;
    }
  }
}

@media only screen and (max-width: 590px) {
  .copyright {
    padding: 0 25px;
    p {
      font-size: 0.7rem !important;
    }
  }
}
