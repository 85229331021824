.pyet-deputet-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ask-deputy {
    border: 1px solid #dc000b;
    padding: 40px;
    width: 80%;
    margin: 80px 0;
  }
}
