.news-related-cards {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
  h2 {
    padding-left: 30px;
    color: #212121;
    font-weight: 900;
    border-left: 3px solid #d90a1b;
    line-height: 1.5;
    margin-bottom: 0;
  }
  .news-contents {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0 40px;
    max-height: 700px;
    overflow: auto;
  }
  .secondary {
    align-self: center;
  }
}

.suggested-news-wrapper {
  width: 80%;
  margin-top: 50px;
}

@media only screen and (max-width: 414px) {
  .news-related-cards {
    width: 80% !important;
    margin-top: 20px !important;
    strong {
      h2 {
        padding-left: 15px;
        font-size: 1.1rem;
      }
    }
    .news-content {
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      max-height: fit-content !important;
      margin-bottom: 20px !important;
      .news-related-comp-wrapper {
        width: 100% !important;
        margin-bottom: 10px !important;
        height: 230px !important;
        img {
          height: 230px !important;
        }
        .overlay {
          height: 230px !important;
        }
        p {
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    }
    .secondary {
      padding: 2% 4%;
    }
  }
}
