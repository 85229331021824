.ligjvenes-ne-vite-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  b {
    width: 70%;
    padding-bottom: 0.35em;
    border-bottom: 3px solid #dc000b;
    font-size: 1.25rem;
    font-weight: 900;
  }
  .ligjvenes-ne-vite-content {
    width: 70%;
    margin: 30px 0;
    a {
      margin-left: 7px;
    }
  }
}

@media only screen and (max-width: 414px) {
  .ligjvenes-ne-vite-wrapper {
    .ligjvenes-ne-vite-content,
    b {
      width: 88%;
    }
    b {
      line-height: 1.4;
    }
  }
}
