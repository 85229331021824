.post-list-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .post-cards-wrapper {
    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    // height: 350px;
    // overflow-y: auto;
    .ant-card {
      width: 100% !important;
      margin-right: 0 !important;
      max-width: 100%;
      // max-height: 300px;
      .ant-card-cover {
        img {
          max-width: 100%;
          max-height: 300px;
        }
      }
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
  }
  .post-script {
    margin-top: -20px;
    color: #dc000b;
    // top: -30px;
  }
}
