.intro-wrapper {
  justify-content: space-between;
  width: 100%;
  margin: 0 !important;
  .ant-col {
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    h2 {
      padding-left: 30px;
      color: #212121;
      font-weight: 900;
      border-left: 3px solid #d90a1b;
      line-height: 1.5;
    }
    p {
      margin-top: 30px;
      line-height: 1.7;
      color: #00000085;
    }
  }
  .photo-wrapper {
    height: 400px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      aspect-ratio: 1;
      width: 60%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .intro-wrapper {
    display: flex !important;
    flex-direction: column !important;
    .ant-col {
      min-width: 100%;
      img {
        display: none;
      }
    }
  }
}
