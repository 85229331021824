.act-timeline-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .loading-akte-timeline {
    margin: 100px 0;
  }
  .act-timeline-pre-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 100px 0;
  }
  .act-timeline-content {
    width: 100%;
    margin-top: 80px;

    .ant-timeline-item {
      padding-bottom: 50px;
    }
    .view-act:hover {
      background: #fff;
      color: #000;
      cursor: default;
    }
  }
  .link-file {
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
  }
  .link-file span {
    padding-right: 10px;
  }
  .link-file:hover {
    color: #1890ff;
  }
}

@media only screen and (max-width: 414px) {
  .act-timeline-wrapper {
    .act-timeline-pre-wrapper {
      flex-direction: column;
      margin: 50px 0;
      .act-timeline-content {
        margin: 0;
      }
    }
    .newsletter-wrapper {
      position: relative !important;
      width: 100% !important;
      height: 190px !important;
      h4 {
        font-size: 1rem;
        line-height: 1.3;
      }
    }
  }
}
