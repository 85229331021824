.pipkip-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .pipkip-comp-wrapper {
    cursor: pointer;
    margin-right: 20px;
    display: flex;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    width: 250px;
    align-items: center;
    font-weight: bolder;
    color: white;
    .icon-part {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      aspect-ratio: 1;
      border-radius: 3px;
      background: linear-gradient(
        150deg,
        rgba(12, 12, 12, 0.8) 0%,
        rgba(5, 3, 3, 0.8) 30%,
        rgba(204, 0, 0, 0.8) 75%,
        rgba(209, 0, 0, 0.8) 100%
      );
      img {
        width: 35px;
        aspect-ratio: 1;
      }
    }
    &:hover {
      .icon-part {
        filter: brightness(1.4);
        transition: 0.3s;
      }
    }
  }
}

@media (max-width: 414px) {
  .pipkip-wrapper {
    width: 100%;
    display: flex !important;
    padding: 0;
    justify-content: space-between !important;
    .pipkip-comp-wrapper {
      margin-right: 0;
      width: 170px;
      font-size: 14px;
      line-height: 1.1;
      .icon-part {
        width: 50px;
        img {
          width: 25px;
        }
      }
    }
  }
}
