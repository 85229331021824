.historiku-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  iframe {
    margin-top: 20px;
    border: none;
  }
  .select-tour {
    margin-top: 8px;
    width: 70%;
  }
  b {
    width: 70%;
    padding-bottom: 0.35em;
    border-bottom: 3px solid #dc000b;
    font-size: 1.25rem;
    font-weight: 900;
  }
  img {
    align-self: flex-start;
    margin-left: calc(100% - 85%);
    margin-top: 20px;
  }
  .historiku-content {
    width: 70%;
    margin: 30px 0;
    h3 {
      font-size: 1rem;
      font-weight: bold;
      margin-top: 15px;
      &:first-child {
        margin: 0;
      }
    }
    p {
      font-size: 0.875rem;
      letter-spacing: 0.05rem;
      line-height: 1.6;
      color: #333;
    }
  }
}

@media only screen and (max-width: 414px) {
  .historiku-wrapper {
    .historiku-content,
    b {
      width: 88%;
    }
    b {
      line-height: 1.4;
    }
    img {
      margin-top: 25px;
      margin-left: calc(100% - 94%);
    }
  }
}
