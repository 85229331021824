.home-header-wrapper {
  background: linear-gradient(
      150deg,
      rgba(12, 12, 12, 0.8) 0%,
      rgba(5, 3, 3, 0.8) 30%,
      rgba(204, 0, 0, 0.8) 75%,
      rgba(209, 0, 0, 0.8) 100%
    ),
    url("../../../assets/images/header-backround.jpg"),
    -100% 0% no-repeat padding-box;
  width: 100%;
  // min-height: 65vh;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  position: relative !important;
  .agenda-list {
    max-height: 290px;
  }
  .header-items-part {
    width: 100%;
  }
  .todays-agenda-part {
    display: flex;
    justify-content: center;
    .agenda {
      width: 75%;
      padding-left: 0px !important;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home-header-wrapper {
    padding: 5%;
    .header-items-part {
      display: flex;
      // margin-bottom: 50px;
      flex-wrap: wrap;
      // height: 60%;
      justify-content: space-between;
    }
    .todays-agenda-part {
      .agenda {
        padding-left: 0px !important;
        .agenda-list {
          width: 100%;
        }
      }
      // .ant-spin-nested-loading {
      //   .ant-spin-blur {
      //     opacity: 0 !important;
      //   }
      // }
    }
  }
}

.ant-spin-nested-loading {
  .ant-spin-blur {
    opacity: 0 !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home-header-wrapper {
    padding: 3% 8%;
    .header-items-part {
      .header-items {
        display: flex;
        // margin-bottom: 50px;
        flex-wrap: wrap;
        // height: 60%;
        // align-content: space-between;
        // justify-content: space-between;
        // padding-right: 3%;
        // width: 90%;
        // margin-left: 10%;
      }
    }
  }
}

@media (max-width: 414px) {
  .home-header-wrapper {
    padding: 7% 7%;
    .header-items-part {
      margin-bottom: 20px;
      .header-items {
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .header-item {
          min-width: 1px;
          width: 50%;
          margin-bottom: 30px;
          .icon-part {
            width: 45px;
            img {
              width: 22px;
            }
          }
          .text-part {
            font-size: 13px !important;
          }
        }
      }
    }
    .todays-agenda-part {
      h1:first-child {
        font-size: 16px;
      }
      .agenda {
        width: 100%;
      }
      .day-picker {
        flex-direction: column-reverse;
        .day-pick {
          text-align: center;
          line-height: 1.1;
          font-size: 14px;
        }
      }
      .agenda-item {
        padding: 2% 3%;
        .agenda-time {
          font-size: 16px;
        }
        .agenda-description {
          font-size: 14px;
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
