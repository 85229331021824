$overlay-bckg: transparent
  linear-gradient(150deg, #0c0c0c 0%, #cc0000 100%, #ce0000 100%, #d10000 100%)
  0% 0% no-repeat padding-box;

@mixin backg-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: fill;
}

.simple-cards-wrapper {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  // margin: 0 !important;
  // flex-flow: nowrap !important;
  margin: 100px 0;
  .simple-cards-container {
    // margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    // justify-content: space-between !important;
    width: 75%;
    // overflow-x: scroll;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      height: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
    .card {
      cursor: pointer;
      min-height: 170px;
      // width: 100px;
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin: 20px 15px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        transition: transform 0.2s;
        transform: scale(1.05);
      }
    }
    // .overlay-bckg {
    //   // @include backg-container;
    //   z-index: 1;
    //   opacity: 0.7;
    //   background: $overlay-bckg;
    // }
    img {
      width: 80px !important;
      aspect-ratio: 1;
      object-fit: contain !important;
      // @include backg-container;
      z-index: -1;
    }
    .bigger-icon {
      // width: 100% !important;
      min-width: 200px;
    }
    .card-content {
      // position: absolute;
      // z-index: 2;
      // bottom: 0;
      // height: 50%;
      // padding: 0 40px 40px;
      // display: flex;
      // align-items: flex-end;
      h4 {
        margin-left: 50px;
        color: #000;
        margin-top: 15px;
        letter-spacing: 0.3px;
        font-size: 1.15rem;
        font-weight: bolder;
        line-height: 1.4;
      }
    }
  }
}

@media (max-width: 414px) {
  .simple-cards-wrapper {
    margin: 0;
    padding: 0 10%;
    .simple-cards-container {
      width: 100%;
      .card {
        justify-content: flex-start;
        min-height: 120px !important;
        img {
          width: 65px !important;
        }
        .bigger-icon {
          min-width: 140px !important;
        }
        h4 {
          margin: 0 0 0 20px;
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }
  }
}
