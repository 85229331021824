.news-layout-wrapper {
  padding-top: 50px;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .news-header {
    font-weight: bolder;
    text-align: center;
    font-size: 23px;
    margin-bottom: 50px;
  }
  .marquee-container {
    width: 92%;
  }
  .news-layout-body {
    width: 92%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 550px;
    margin-top: 15px;
    // padding-left: 4%;
    // padding-right: 4%;
    justify-content: space-between;
    align-content: space-between;
    .news-card {
      cursor: pointer;
      position: relative;
      &:first-child {
        width: 48%;
        height: 550px;
        object-fit: cover;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      width: 50%;
      height: 270px;
      object-fit: cover;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .news-overlay {
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        background-position-y: 0px;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        top: 0;
        padding: 20px;
        transition: all 250ms ease;
        &:hover {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          background-position-y: 0px;
          background-size: 350% 350%;
        }

        .news-card-title {
          color: white;
          font-size: 22px;
          font-weight: bold;
        }
        .news-card-date {
          color: white;
          font-size: 18px;
        }
      }
    }
  }
  .news-read-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 100%;
  }
}

@media (max-width: 414px) {
  .news-layout-wrapper {
    padding: 8% 8%;
    .news-header {
      margin: 0;
      line-height: 1;
    }
    .news-layout-body {
      width: 100%;
      margin: 20px 0;
      height: auto;
      .news-card {
        width: 100% !important;
        height: 190px !important;
        margin: 5px 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .news-overlay {
          padding: 15px;
          .news-card-title {
            font-size: 14px;
          }
          .news-card-date {
            font-size: 14px;
          }
        }
      }
    }
    .news-read-more {
      margin: 0;
    }
  }
}
