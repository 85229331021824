.acts-filters {
  margin: 50px 0;
  .ant-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .ant-col {
      display: flex;
      justify-content: flex-start;
      .ant-form-item {
        width: 350px;
        .ant-picker-range {
          width: 350px;
        }
      }
    }
    .ant-col-8 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      span {
        opacity: 0.7;
      }
      .ant-input,
      .ant-picker,
      .ant-select-selector {
        height: 40px;
        display: flex;
        align-items: center;
      }
      .ant-select-arrow {
        align-self: center;
      }
    }
    .ant-col-7,
    .ant-col-4,
    .ant-col-3 {
      .ant-input,
      .ant-picker,
      .ant-select-selector,
      .ant-input-number {
        height: 40px !important;
        display: flex;
        align-items: center;
      }
      .ant-input-number {
        width: 160px;
      }
      .ant-picker {
        width: 160px;
      }
    }
  }
  .ant-btn-primary {
    background-color: #212121 !important;
    border: 1px solid rgba(190, 190, 190, 0.5) !important;
    border-radius: 3px;
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    margin-top: 10px;
    &:hover {
      color: #fff;
      filter: brightness(1.3);
    }
    &:first-child {
      margin-right: 20px;
    }
  }
}
