.header-item {
  display: flex;
  min-width: 250px;
  margin-bottom: 50px;
  cursor: pointer;
  .icon-part {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    aspect-ratio: 1;
    width: 77px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 150ms linear;
    img {
      width: 40px;
      aspect-ratio: 1;
      object-fit: contain;
      color: #fff !important;
      filter: opacity(0.5) drop-shadow(0 0 0 #fff);
    }
  }
  .text-part {
    display: flex;
    align-items: center;
    color: white;
    text-transform: uppercase;
    padding-left: 10px;
    max-width: 140px;
  }
  &:hover {
    .icon-part {
      background-color: rgba(255, 255, 255, 0.315);
    }
  }
}
