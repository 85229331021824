.dynamic-header {
  background: linear-gradient(
      150deg,
      rgba(12, 12, 12, 0.8) 0%,
      rgba(5, 3, 3, 0.8) 30%,
      rgba(204, 0, 0, 0.8) 75%,
      rgba(209, 0, 0, 0.8) 100%
    ),
    url("../../../assets/images/header-backround.jpg"),
    -100% 0% no-repeat padding-box;
  width: 100%;
  padding: 3% 11%;
  min-height: 250px;
  background-size: cover;
  display: flex;
  justify-content: left;
  align-items: center;
  h1 {
    color: white;
    font-weight: bolder;
    margin: 0;
  }
}

@media only screen and (max-width: 414px) {
  .dynamic-header {
    min-height: 80px;
    padding: 5% 10%;
    h1 {
      font-size: 1.1rem;
      line-height: 1.3;
    }
  }
}
