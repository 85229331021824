.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .navbar-wrapper {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex: 1 1;
    justify-content: center;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    .ant-dropdown-trigger {
      text-align: center;
    }
    a {
      padding-left: 35px;
      color: #000;
      &:hover {
        color: #dc000b;
      }
    }
  }
  .pre-nav-section {
    width: 77%;
    padding: 2% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-part {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 130px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
          transition: 0.4s;
        }
      }
      .logo-name {
        font-size: 18px;
        font-family: "Times New Roman", Times, serif;
        b {
          font-weight: 100;
          font-size: 22px;
        }
      }
    }
    .right-part {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .search-wrapp,
      .live-wrapp,
      .lang-wrapp {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 414px) {
  .header {
    .pre-nav-section {
      width: 85%;
      padding: 5% 0;
      .logo-part {
        img {
          width: 100px !important;
        }
      }
      .logo-name {
        font-size: 14px !important;
        b {
          font-size: 18px !important;
        }
      }
      .right-part {
        margin-top: 20px;
        .outer-circle-2 {
          width: 45px;
          .outer-circle-1 {
            width: 35px;
          }
        }
      }
    }
  }
}
