.news-related-comp-wrapper {
  aspect-ratio: 1;
  width: 30%;
  height: 350px;
  background-color: red;
  border-radius: 3px;
  position: relative;
  margin: 10px 0;
  cursor: pointer;
  img {
    position: absolute;
    width: 100%;
    height: 350px;
    aspect-ratio: 1;
    object-fit: cover;
    z-index: 1;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 350px;
    aspect-ratio: 1;
    object-fit: cover;
    background: linear-gradient(
      344deg,
      rgba(204, 0, 0, 0.8) 0%,
      rgba(12, 12, 12, 0.8) 74%
    );
    z-index: 2;
    transition: all 150ms linear;
    &:hover {
      filter: brightness(1.4);
    }
  }
  p {
    position: absolute;
    z-index: 3;
    color: white;
    font-size: 18px;
    font-weight: bolder;
    width: 70%;
    top: 10%;
    left: 10%;
  }
  .go-to-btn {
    position: absolute;
    z-index: 3;
    color: white;
    bottom: 10%;
    left: 10%;
  }
}
