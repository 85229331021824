@mixin pos_right {
  display: flex;
  align-items: center;
  align-self: flex-end;
  text-align: end;
  font-size: 1.1rem;
}

@mixin pos_left {
  display: flex;
  align-items: center;
  align-self: flex-start;
  text-align: start;
  font-size: 1.1rem;
}

.kryetari-item-wrapper {
  width: 330px;
  display: flex;
  flex-direction: column;
  .stats {
    display: flex;
    strong {
      font-size: 2.3rem;
    }
  }
  p {
    margin: 0;
    line-height: 1.4;
    opacity: 0.75;
  }
  .right {
    @include pos_right;
  }
  .left {
    @include pos_left;
  }
  span {
    display: flex;
    align-items: center;
    h6 {
      font-size: 0.8rem;
      margin-bottom: 0;
      margin-left: 3px;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: #dc000b;
      }
    }
  }
  .ant-select {
    margin: 13px 0;
  }
}

@media only screen and (max-width: 1170px) {
  .kryetari-item-wrapper {
    width: 300px;
    // p {
    //   display: none !important;
    // }
  }
}

@media only screen and (max-width: 1000px) {
  .kryetari-item-wrapper {
    width: 260px;
  }
}

@media only screen and (max-width: 1000px) {
  .kryetari-item-wrapper {
    width: 220px;
  }
}

@media only screen and (max-width: 800px) {
  .kryetari-item-wrapper {
    width: 100% !important;
    margin: 15px 0;
    .right {
      align-self: flex-start !important;
      text-align: start;
      font-size: 15px;
    }
    .ant-select {
      margin: 7px 0 !important;
    }
  }
}
