.member-card {
  .ant-card {
    max-width: 250px;
  }
  .ant-card-bordered {
    // border: none;
  }
  .ant-card-hoverable:hover {
    // border-color: transparent;
    // box-shadow: none;
  }
  .ant-card-meta-detail {
    // overflow: visible !important;
    .ant-card-meta-description {
      text-align: center;
      word-break: break-word;
      font-size: 0.8rem !important;
    }
  }
  .ant-card-cover {
    // width: 230px;
    // height: 230px;
    // border-radius: 360px;
    // margin: 0 auto;

    img {
      //  border-radius: 360px;
      min-width: 100%;
      height: 200px;
      object-fit: cover;
      //  &:hover {
      //    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(2, 2, 2, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
      //    transition: .3s;
      //  }
    }
  }
}
